// 表单属性【右面板】
export const formConf = {
  formRef: "elForm",
  formModel: "formData",
  size: "medium",
  labelPosition: "right",
  labelWidth: 100,
  formRules: "rules",
  gutter: 15,
  disabled: false,
  span: 24,
  formBtns: true,
};

// 输入型组件 【左面板】
export const inputComponents = [
  {
    // 组件的自定义配置
    __config__: {
      label: "单行文本",
      labelWidth: null,
      showLabel: true,
      changeTag: true,
      tag: "el-input",
      tagIcon: "input",
      defaultValue: undefined,
      required: false,
      layout: "colFormItem",
      span: 24,
      document: "https://element.eleme.cn/#/zh-CN/component/input",
      // 正则校验规则
      regList: [],
    },
    // 组件的插槽属性
    __slot__: {
      prepend: "",
      append: "",
    },
    alias:'',
    // 其余的为可直接写在组件标签上的属性
    type: "textarea",
    placeholder: "请输入",
    style: { width: "100%" },
    clearable: true,
    autosize: {
      minRows: 1,
    },
    "prefix-icon": "",
    "suffix-icon": "",
    maxlength: 150,
    "show-word-limit": false,
    readonly: false,
    disabled: false,
  },
  {
    // 组件的自定义配置
    __config__: {
      label: "链接",
      labelWidth: null,
      showLabel: true,
      changeTag: true,
      tag: "el-input",
      tagIcon: "link",
      defaultValue: undefined,
      required: false,
      layout: "colFormItem",
      span: 24,
      document: "https://element.eleme.cn/#/zh-CN/component/input",
      regList: [],
      type: "link",
    },
    alias:'',
    type: "link",
    placeholder: "请输入以https://或http://开头的",
    style: { width: "100%" },
    maxlength: 250,
    readonly: false,
    disabled: false,
  },
  {
    __config__: {
      label: "多行文本",
      type: "textarea",
      labelWidth: null,
      showLabel: true,
      tag: "el-input",
      tagIcon: "textarea",
      defaultValue: undefined,
      required: false,
      layout: "colFormItem",
      span: 24,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/input",
    },
    alias:'',
    type: "textarea",
    placeholder: "请输入",
    autosize: {
      minRows: 4,
      // maxRows: 4,
    },
    style: { width: "100%" },
    maxlength: 500,
    "show-word-limit": false,
    readonly: false,
    disabled: false,
  },
  // {
  //   // 组件的自定义配置
  //   __config__: {
  //     label: "数字",
  //     labelWidth: null,
  //     showLabel: true,
  //     changeTag: true,
  //     tag: "el-input",
  //     tagIcon: "inumber",
  //     defaultValue: undefined,
  //     required: true,
  //     layout: "colFormItem",
  //     span: 24,
  //     document: "https://element.eleme.cn/#/zh-CN/component/input",
  //     // 正则校验规则
  //     regList: [],
  //   },
  //   // 组件的插槽属性
  //   __slot__: {
  //     prepend: "",
  //     append: "",
  //   },
  //   // 其余的为可直接写在组件标签上的属性
  //   type: "number",
  //   placeholder: "请输入",
  //   style: { width: "100%" },
  //   clearable: true,
  //   "prefix-icon": "",
  //   "suffix-icon": "",
  //   maxlength: null,
  //   readonly: false,
  //   disabled: false,
  // },
  // {
  //   __config__: {
  //     label: "密码",
  //     showLabel: true,
  //     labelWidth: null,
  //     changeTag: true,
  //     tag: "el-input",
  //     tagIcon: "password",
  //     defaultValue: undefined,
  //     layout: "colFormItem",
  //     span: 24,
  //     required: true,
  //     regList: [],
  //     document: "https://element.eleme.cn/#/zh-CN/component/input",
  //   },
  //   __slot__: {
  //     prepend: "",
  //     append: "",
  //   },
  //   placeholder: "请输入",
  //   "show-password": true,
  //   style: { width: "100%" },
  //   clearable: true,
  //   "prefix-icon": "",
  //   "suffix-icon": "",
  //   maxlength: null,
  //   "show-word-limit": false,
  //   readonly: false,
  //   disabled: false,
  // },
  {
    __config__: {
      label: "计数器",
      showLabel: true,
      changeTag: true,
      labelWidth: null,
      tag: "el-input-number",
      tagIcon: "number",
      defaultValue: undefined,
      span: 24,
      layout: "colFormItem",
      required: false,
      total: false,
      regList: [],
      document: "https://element.eleme.cn/#/zh-CN/component/input-number",
    },
    alias:'',
    placeholder: "",
    min: undefined,
    max: 1000,
    step: 0,
    "step-strictly": false,
    precision: undefined,
    "controls-position": "",
    disabled: false,
  },
  // {
  //   __config__: {
  //     label: "编辑器",
  //     showLabel: true,
  //     changeTag: true,
  //     labelWidth: null,
  //     tag: "tinymce",
  //     tagIcon: "rich-text",
  //     defaultValue: null,
  //     span: 24,
  //     layout: "colFormItem",
  //     required: true,
  //     regList: [],
  //     document: "http://tinymce.ax-z.cn",
  //     readonly: true,
  //   },
  //   placeholder: "请输入",
  //   height: 300, // 编辑器高度
  //   branding: false, // 隐藏右下角品牌烙印
  // },
];

// 选择型组件 【左面板】
export const selectComponents = [
  {
    __config__: {
      label: "下拉选择",
      selectType: "select",
      url: "/bpm/sys/rank/list-all-simple",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-select",
      tagIcon: "select",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "static",
      span: 24,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/select",
    },
    alias:'',
    __slot__: {
      options: [],
    },
    props: {
      props: {
        multiple: false,
        label: "label",
        value: "value",
        // children: "children",
      },
    },
    placeholder: "请选择",
    style: { width: "100%" },
    "show-all-levels": false,
    clearable: true,
    "popper-append-to-body": false,
    disabled: false,
    filterable: true,
    separator: "/",
  },
  {
    __config__: {
      label: "级联选择",
      selectType: "cascader",
      url: "/bpm/sys/rank/list-all-simple",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-cascader",
      tagIcon: "cascader",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "static",
      span: 24,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/cascader",
    },
    alias:'',
    options: [
      // {
      //   id: 1,
      //   value: 1,
      //   label: "选项1",
      //   children: [
      //     {
      //       id: 2,
      //       value: 2,
      //       label: "选项1-1",
      //     },
      //   ],
      // },
    ],
    placeholder: "请选择",
    style: { width: "100%" },
    props: {
      props: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
      },
    },
    "show-all-levels": false,
    disabled: false,
    clearable: true,
    "append-to-body": false,
    filterable: true,
    separator: "/",
  },
  {
    __config__: {
      label: "单选框组",
      selectType: "radio",
      url: "/bpm/sys/rank/list-all-simple",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      defaultValue: [],
      dataType: "static",
      labelWidth: null,
      showLabel: true,
      tag: "el-radio-group",
      tagIcon: "radio",
      changeTag: true,
      defaultValue: undefined,
      layout: "colFormItem",
      span: 24,
      optionType: "default",
      regList: [],
      required: false,
      border: false,
      document: "https://element.eleme.cn/#/zh-CN/component/radio",
    },
    alias:'',
    __slot__: {
      options: [],
    },
    style: {},
    size: "medium",
    disabled: false,
    hideRadio: false,
  },
  {
    __config__: {
      label: "多选框组",
      selectType: "checkbox",
      defaultValue: [],
      url: "/bpm/sys/rank/list-all-simple",
      method: "get",
      dataPath: "list",
      dataType: "static",
      tag: "el-checkbox-group",
      tagIcon: "checkbox",
      span: 24,
      showLabel: true,
      labelWidth: null,
      layout: "colFormItem",
      optionType: "default",
      required: false,
      regList: [],
      changeTag: true,
      border: false,
      document: "https://element.eleme.cn/#/zh-CN/component/checkbox",
    },
    alias:'',
    __slot__: {
      options: [],
    },
    style: {},
    size: "medium",
    disabled: false,
    hideRadio: false,
  },
  {
    __config__: {
      label: "选项关联",
      selectType: "select",
      url: "e",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-select",
      tagIcon: "selectOptions",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "static",
      span: 24,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/select",
      type: "selectOptions",
    },
    alias:'',
    __slot__: {
      options: [],
    },
    props: {
      props: {
        multiple: false,
        label: "label",
        value: "value",
        // children: "children",
      },
    },
    isAssociation: true,
    placeholder: "请选择",
    style: { width: "100%" },
    "show-all-levels": false,
    clearable: true,
    disabled: false,
    "popper-append-to-body": false,
    filterable: true,
    separator: "/",
  },
  // {
  //   __config__: {
  //     label: "开关",
  //     tag: "el-switch",
  //     tagIcon: "switch",
  //     defaultValue: false,
  //     span: 24,
  //     showLabel: true,
  //     labelWidth: null,
  //     layout: "colFormItem",
  //     required: true,
  //     regList: [],
  //     changeTag: true,
  //     document: "https://element.eleme.cn/#/zh-CN/component/switch",
  //   },
  //   style: {},
  //   disabled: false,
  //   "active-text": "",
  //   "inactive-text": "",
  //   "active-color": null,
  //   "inactive-color": null,
  //   "active-value": true,
  //   "inactive-value": false,
  // },
  // {
  //   __config__: {
  //     label: "滑块",
  //     tag: "el-slider",
  //     tagIcon: "slider",
  //     defaultValue: null,
  //     span: 24,
  //     showLabel: true,
  //     layout: "colFormItem",
  //     labelWidth: null,
  //     required: true,
  //     regList: [],
  //     changeTag: true,
  //     document: "https://element.eleme.cn/#/zh-CN/component/slider",
  //   },
  //   disabled: false,
  //   min: 0,
  //   max: 100,
  //   step: 1,
  //   "show-stops": false,
  //   range: false,
  // },
  {
    __config__: {
      label: "时间选择",
      tag: "el-time-picker",
      tagIcon: "time",
      defaultValue: null,
      span: 24,
      showLabel: true,
      layout: "colFormItem",
      labelWidth: null,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/time-picker",
    },
    alias:'',
    placeholder: "请选择",
    style: { width: "100%" },
    disabled: false,
    "append-to-body": false,
    clearable: true,
    "picker-options": {
      selectableRange: "00:00:00-23:59:59",
    },
    format: "HH:mm:ss",
    "value-format": "HH:mm:ss",
  },
  {
    __config__: {
      label: "时间范围",
      tag: "el-time-picker",
      tagIcon: "time-range",
      span: 24,
      showLabel: true,
      labelWidth: null,
      layout: "colFormItem",
      defaultValue: null,
      required: false,
      regList: [],
      changeTag: true,
      ifRange: false,
      document: "https://element.eleme.cn/#/zh-CN/component/time-picker",
    },
    alias:'',
    style: { width: "100%" },
    disabled: false,
    clearable: true,
    "append-to-body": false,
    "is-range": true,
    "range-separator": "至",
    "start-placeholder": "开始时间",
    "end-placeholder": "结束时间",
    format: "HH:mm:ss",
    "value-format": "HH:mm:ss",
  },
  {
    __config__: {
      label: "日期选择",
      tag: "el-date-picker",
      tagIcon: "date",
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      span: 24,
      layout: "colFormItem",
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/date-picker",
    },
    alias:'',
    placeholder: "请选择",
    type: "date",
    style: { width: "100%" },
    disabled: false,
    clearable: true,
    "append-to-body": false,
    format: "yyyy-MM-dd",
    "value-format": "yyyy-MM-dd",
    readonly: false,
  },
  {
    __config__: {
      label: "日期范围",
      tag: "el-date-picker",
      tagIcon: "date-range",
      defaultValue: null,
      span: 24,
      showLabel: true,
      labelWidth: null,
      required: false,
      layout: "colFormItem",
      regList: [],
      changeTag: true,
      ifRange: false,
      document: "https://element.eleme.cn/#/zh-CN/component/date-picker",
    },
    alias:'',
    style: { width: "100%" },
    type: "daterange",
    "range-separator": "至",
    "start-placeholder": "开始日期",
    "end-placeholder": "结束日期",
    disabled: false,
    clearable: true,
    "append-to-body": false,
    format: "yyyy-MM-dd",
    "value-format": "yyyy-MM-dd",
    readonly: false,
  },
  // {
  //   __config__: {
  //     label: "评分",
  //     tag: "el-rate",
  //     tagIcon: "rate",
  //     defaultValue: 0,
  //     span: 24,
  //     showLabel: true,
  //     labelWidth: null,
  //     layout: "colFormItem",
  //     required: true,
  //     regList: [],
  //     changeTag: true,
  //     document: "https://element.eleme.cn/#/zh-CN/component/rate",
  //   },
  //   style: {},
  //   max: 5,
  //   "allow-half": false,
  //   "show-text": false,
  //   "show-score": false,
  //   disabled: false,
  // },
  // {
  //   __config__: {
  //     label: "颜色选择",
  //     tag: "el-color-picker",
  //     tagIcon: "color",
  //     span: 24,
  //     defaultValue: null,
  //     showLabel: true,
  //     labelWidth: null,
  //     layout: "colFormItem",
  //     required: true,
  //     regList: [],
  //     changeTag: true,
  //     document: "https://element.eleme.cn/#/zh-CN/component/color-picker",
  //   },
  //   "show-alpha": false,
  //   "color-format": "",
  //   disabled: false,
  //   size: "medium",
  // },
  {
    __config__: {
      label: "上传",

      tag: "el-upload",
      tagIcon: "upload",
      layout: "colFormItem",
      defaultValue: [],
      showLabel: true,
      labelWidth: null,
      required: false,
      span: 24,
      showTip: false,
      buttonText: "点击上传",
      regList: [],
      changeTag: true,
      fileSize: 300,
      sizeUnit: "MB",
      document: "https://element.eleme.cn/#/zh-CN/component/upload",
    },
    alias:'',
    __slot__: {
      "list-type": true,
    },
    // action: process.env.VUE_APP_BASE_API + "/admin-api/infra/file/upload", // 请求地址
    action: "https://up-z2.qiniup.com", // 请求地址
    data: {
      key: "",
      token: "",
    },
    disabled: false,
    accept: ".pdf,.xls,.xlsx,.doc,.docx,.mp4,image/*",
    name: "file",
    "auto-upload": true,
    "list-type": "picture",
    multiple: true,
  },
];

// 布局型组件 【左面板】
export const layoutComponents = [
  {
    __config__: {
      layout: "rowFormItem",
      tagIcon: "row",
      tag: "el-row",
      label: "行容器",
      form: {
        标题: "容器",
        动作名称: "添加",
        填写方式: "列表",
        // 打印格式: "横向打印",
      },
      layoutTree: true,
      document:
        "https://element.eleme.cn/#/zh-CN/component/layout#row-attributes",
    },
    alias:'',
    type: "default",
    justify: "start",
    align: "top",
  },
  // {
  //   __config__: {
  //     label: "按钮",
  //     showLabel: true,
  //     changeTag: true,
  //     labelWidth: null,
  //     tag: "el-button",
  //     tagIcon: "button",
  //     span: 24,
  //     layout: "colFormItem",
  //     document: "https://element.eleme.cn/#/zh-CN/component/button",
  //   },
  //   __slot__: {
  //     default: "主要按钮",
  //   },
  //   type: "primary",
  //   icon: "el-icon-search",
  //   round: false,
  //   size: "medium",
  //   plain: false,
  //   circle: false,
  //   disabled: false,
  // },
  // {
  //   __config__: {
  //     layout: "colFormItem",
  //     tagIcon: "table",
  //     tag: "el-table",
  //     document: "https://element.eleme.cn/#/zh-CN/component/table",
  //     span: 24,
  //     formId: 101,
  //     renderKey: 1595761764203,
  //     componentName: "row101",
  //     showLabel: true,
  //     changeTag: true,
  //     labelWidth: null,
  //     label: "表格[开发中]",
  //     dataType: "dynamic",
  //     method: "get",
  //     dataPath: "list",
  //     dataConsumer: "data",
  //     url: "https://www.fastmock.site/mock/f8d7a54fb1e60561e2f720d5a810009d/fg/tableData",
  //     children: [
  //       {
  //         __config__: {
  //           layout: "raw",
  //           tag: "el-table-column",
  //           renderKey: 15957617660153,
  //         },
  //         prop: "date",
  //         label: "日期",
  //       },
  //       {
  //         __config__: {
  //           layout: "raw",
  //           tag: "el-table-column",
  //           renderKey: 15957617660152,
  //         },
  //         prop: "address",
  //         label: "地址",
  //       },
  //       {
  //         __config__: {
  //           layout: "raw",
  //           tag: "el-table-column",
  //           renderKey: 15957617660151,
  //         },
  //         prop: "name",
  //         label: "名称",
  //       },
  //       {
  //         __config__: {
  //           layout: "raw",
  //           tag: "el-table-column",
  //           renderKey: 1595774496335,
  //           children: [
  //             {
  //               __config__: {
  //                 label: "按钮",
  //                 tag: "el-button",
  //                 tagIcon: "button",
  //                 layout: "raw",
  //                 renderKey: 1595779809901,
  //               },
  //               __slot__: {
  //                 default: "主要按钮",
  //               },
  //               type: "primary",
  //               icon: "el-icon-search",
  //               round: false,
  //               size: "medium",
  //             },
  //           ],
  //         },
  //         label: "操作",
  //       },
  //     ],
  //   },
  //   data: [],
  //   directives: [
  //     {
  //       name: "loading",
  //       value: true,
  //     },
  //   ],
  //   border: true,
  //   type: "default",
  //   justify: "start",
  //   align: "top",
  // },
];
// 增强型组件 【左面板】
export const newSelectComponents = [
  {
    __config__: {
      label: "部门",
      type: "department",
      url: "bpm/flowManagement/deptTree",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-cascader",
      tagIcon: "department",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "dynamic",
      span: 24,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/cascader",
    },
    alias:'',
    options: [],
    placeholder: "请选择",
    style: { width: "100%" },
    props: {
      checkStrictly: true,
      props: {
        multiple: false,
        label: "label",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
    },
    "show-all-levels": false,
    disabled: false,
    clearable: true,
    "append-to-body": false,
    filterable: true,
    separator: "/",
  },
  {
    __config__: {
      label: "职位",
      type: "position",
      url: "/bpm/sys/post/list-all-simple",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-select",
      tagIcon: "position",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "dynamic",
      span: 24,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/cascader",
    },
    alias:'',
    __slot__: {
      options: [],
    },
    placeholder: "请选择",
    style: { width: "100%" },
    props: {
      props: {
        multiple: false,
        label: "label",
        value: "id",
        children: "children",
      },
    },
    "show-all-levels": false,
    disabled: false,
    clearable: true,
    "popper-append-to-body": false,
    filterable: true,
    separator: "/",
  },
  {
    __config__: {
      label: "职级",
      type: "rank",
      url: "/bpm/sys/rank/list-all-simple",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-select",
      tagIcon: "rank",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "dynamic",
      span: 24,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/cascader",
    },
    alias:'',
    __slot__: {
      options: [
        // {
        //   label: "选项一",
        //   value: 1,
        // },
        // {
        //   label: "选项二",
        //   value: 2,
        // },
      ],
    },
    placeholder: "请选择",
    style: { width: "100%" },
    props: {
      props: {
        multiple: false,
        label: "label",
        value: "id",
        children: "children",
      },
    },
    "show-all-levels": false,
    disabled: false,
    clearable: true,
    "popper-append-to-body": false,
    filterable: true,
    separator: "/",
  },
  {
    __config__: {
      label: "联系人",
      type: "contacts",
      url: "/system/bpm/user/list-all-simple",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-select",
      tagIcon: "contacts",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "dynamic",
      span: 24,
      required: false,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/cascader",
    },
    alias:'',
    __slot__: {
      options: [],
    },
    placeholder: "请选择",
    style: { width: "100%" },
    props: {
      props: {
        multiple: false,
        label: "label",
        value: "id",
        children: "children",
      },
    },
    "show-all-levels": false,
    disabled: false,
    clearable: true,
    "popper-append-to-body": false,
    filterable: true,
    separator: "/",
  },
  {
    __config__: {
      label: "实际申请人",
      type: "litigant",
      url: "/system/bpm/user/list-all-simple",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-select",
      tagIcon: "litigant",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "dynamic",
      span: 24,
      required: true,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/cascader",
    },
    alias:'',
    __slot__: {
      options: [],
    },
    placeholder: "请选择",
    style: { width: "100%" },
    props: {
      props: {
        multiple: false,
        label: "label",
        value: "id",
        children: "children",
      },
    },
    "show-all-levels": false,
    disabled: false,
    clearable: true,
    "popper-append-to-body": false,
    filterable: true,
    separator: "/",
  },
  {
    __config__: {
      label: "关联审批",
      changeContacts: true,
      approval: "",
      type: "approval",
      url: "/bpm/flowManagement/relationApproval",
      method: "get",
      dataPath: "list",
      dataConsumer: "options",
      showLabel: true,
      labelWidth: null,
      tag: "el-cascader",
      tagIcon: "approval",
      layout: "colFormItem",
      defaultValue: [],
      dataType: "approval",
      span: 24,
      required: false,
      options: [],
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/cascader",
    },
    alias:'',
    options: [],
    placeholder: "请选择",
    style: { width: "100%" },
    props: {
      props: {
        multiple: true,
        label: "label",
        value: "value",
        children: "children",
      },
    },
    "show-all-levels": false,
    disabled: false,
    clearable: true,
    filterable: true,
    "popper-append-to-body": false,
    separator: "/",
    type: "approval",
    ref: "",
    requireArr: ['approval'],
  },
  {
    // 组件的自定义配置
    __config__: {
      label: "金额",
      type: "amountMoney",
      formula: [],
      labelWidth: null,
      showLabel: true,
      changeTag: true,
      tag: "el-input",
      tagIcon: "amoney",
      defaultValue: undefined,
      required: false,
      total: false,
      layout: "colFormItem",
      span: 24,
      document: "https://element.eleme.cn/#/zh-CN/component/input",
      ifBig: false,
      // 正则校验规则
      regList: [],
    },
    alias:'',
    // 组件的插槽属性
    __slot__: {
      prepend: "",
      append: "",
    },
    // 其余的为可直接写在组件标签上的属性
    type: "number",
    placeholder: "请输入",
    style: { width: "100%" },
    clearable: true,
    "prefix-icon": "",
    "suffix-icon": "",
    readonly: false,
    disabled: false,
  },
];

// 工具型组件 【左面板】
export const toolComponents = [
  {
    __config__: {
      layout: "rowFormItem",
      tagIcon: "row",
      tag: "el-row",
      label: "请假申请",
      form: {
        标题: "请假申请",
        动作名称: "无",
        填写方式: "列表",
        type: "leave",
      },
      componentName: "leave",
      layoutTree: true,
      document:
        "https://element.eleme.cn/#/zh-CN/component/layout#row-attributes",
      children: [
        [
          {
            __config__: {
              label: "开始时间",
              tag: "el-date-picker",
              tagIcon: "date",
              defaultValue: null,
              showLabel: true,
              labelWidth: null,
              span: 13,
              layout: "colFormItem",
              required: true,
              regList: [],
              changeTag: true,
              document:
                "https://element.eleme.cn/#/zh-CN/component/date-picker",
              formId: 1724060738332,
              renderKey: "17240607383321724060738332",
              _type_: "leave",
            },
            alias:'',
            placeholder: "请选择",
            type: "date",
            style: {
              width: "100%",
            },
            disabled: false,
            clearable: true,
            "append-to-body": false,
            format: "yyyy-MM-dd",
            "value-format": "yyyy-MM-dd",
            readonly: false,
            __vModel__: "field1724060738332",
          },
          {
            __config__: {
              label: "时间段",
              selectType: "select",
              url: "/bpm/sys/rank/list-all-simple",
              method: "get",
              dataPath: "list",
              dataConsumer: "options",
              showLabel: true,
              labelWidth: null,
              tag: "el-select",
              tagIcon: "select",
              layout: "colFormItem",
              defaultValue: [],
              dataType: "static",
              span: 11,
              required: true,
              regList: [],
              changeTag: true,
              document: "https://element.eleme.cn/#/zh-CN/component/select",
              formId: 1724060746228,
              renderKey: "17240607462281724060746228",
              _type_: "leave",
            },
            alias:'',
            __slot__: {
              options: [
                {
                  label: "上午",
                  value: "上午",
                },
                {
                  label: "下午",
                  value: "下午",
                },
              ],
            },
            props: {
              props: {
                multiple: false,
                label: "label",
                value: "value",
              },
            },
            placeholder: "请选择",
            style: {
              width: "100%",
            },
            "show-all-levels": false,
            clearable: true,
            disabled: false,
            "popper-append-to-body": false,
            filterable: false,
            separator: "/",
            __vModel__: "field1724060746228",
          },
          {
            __config__: {
              label: "结束时间",
              tag: "el-date-picker",
              tagIcon: "date",
              defaultValue: null,
              showLabel: true,
              labelWidth: null,
              span: 13,
              layout: "colFormItem",
              required: true,
              regList: [],
              changeTag: true,
              document:
                "https://element.eleme.cn/#/zh-CN/component/date-picker",
              formId: 1724060774790,
              renderKey: "17240607747901724060774790",
              _type_: "leave",
            },
            alias:'',
            placeholder: "请选择",
            type: "date",
            style: {
              width: "100%",
            },
            disabled: false,
            clearable: true,
            "append-to-body": false,
            format: "yyyy-MM-dd",
            "value-format": "yyyy-MM-dd",
            readonly: false,
            __vModel__: "field1724060774790",
          },
          {
            __config__: {
              label: "时间段",
              selectType: "select",
              url: "/bpm/sys/rank/list-all-simple",
              method: "get",
              dataPath: "list",
              dataConsumer: "options",
              showLabel: true,
              labelWidth: null,
              tag: "el-select",
              tagIcon: "select",
              layout: "colFormItem",
              defaultValue: [],
              dataType: "static",
              span: 11,
              required: true,
              regList: [],
              changeTag: true,
              document: "https://element.eleme.cn/#/zh-CN/component/select",
              formId: 1724060779370,
              renderKey: "17240607793701724060779370",
              _type_: "leave",
            },
            alias:'',
            __slot__: {
              options: [
                {
                  label: "上午",
                  value: "上午",
                },
                {
                  label: "下午",
                  value: "下午",
                },
              ],
            },
            props: {
              props: {
                multiple: false,
                label: "label",
                value: "value",
              },
            },
            placeholder: "请选择",
            style: {
              width: "100%",
            },
            "show-all-levels": false,
            clearable: true,
            disabled: false,
            "popper-append-to-body": false,
            filterable: false,
            separator: "/",
            __vModel__: "field1724060779370",
          },
          {
            __config__: {
              label: "时长（天）",
              showLabel: true,
              changeTag: true,
              labelWidth: null,
              tag: "el-input-number",
              tagIcon: "number",
              span: 24,
              layout: "colFormItem",
              required: true,
              regList: [],
              document:
                "https://element.eleme.cn/#/zh-CN/component/input-number",
              formId: 1724153628593,
              renderKey: "17241536285931724153628593",
              _type_: "leave",
            },
            alias:'',
            placeholder: "时长（天）",
            max: 1000,
            step: 1,
            "step-strictly": false,
            precision: 1,
            "controls-position": "",
            disabled: true,
            __vModel__: "field1724060921495",
          },
        ],
      ],
    },
    alias:'',
    type: "default",
    justify: "start",
    align: "top",
  },
];

export const newKitComponents = [];
