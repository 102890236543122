<template>
    <div class="advanced_setup">
        <div class="left">
            <div class="setup_list">
                <div class="setup_item" v-for="item in setupList" :class="setupCode == item.code ? 'setup_active' : ''"
                    :key="item.code" @click="chosseSetup(item.code)">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="right">
            <p>{{ setupList.find((e) => e.code == setupCode).name }}</p>
            <template v-if="setupCode == 1">
                <el-row>
                    <el-col :span="8" v-for="item in deployList" :key="item.name">
                        <div class="deploy_item">
                            <div class="top">
                                <div>
                                    <img :src="item.icon" alt="" />
                                    <span>{{ item.name }}</span>
                                </div>
                                <el-switch v-model="item.value" active-color="#5669ec" inactive-color="#5669ec1a"
                                    :active-value="1" :inactive-value="0">
                                </el-switch>
                            </div>
                            <div class="bottom">{{ item.text }}</div>
                        </div>
                    </el-col>
                </el-row>
            </template>
            <template v-if="setupCode == 2">
                <div class="control_limit">
                    <p>管理范围</p>
                    <div class="list">
                        <!-- <div class="item" :class="authType == item.value ? 'limit_active' : ''"
                            v-for="item in controlLimit" :key="item.value" @click="chooseLimit(item.value)">
                            {{ item.name }}
                        </div> -->
                        <div class="item" v-for="item in controlLimit" :key="item.value"
                            @click="chooseLimit(item.value)">
                            <img src="@/assets/check4.png" alt="" v-if="authType == item.value" />
                            <img src="@/assets/check3.png" alt="" v-else />
                            <div>{{ item.name }}</div>
                        </div>
                    </div>

                    <template v-if="authType == 5">
                        <el-button type="text" @click="openRange(0)"
                            v-if="customRange.uid.length || customRange.dept.length">
                            <span v-if="customRange.dept.length">{{ customRange.dept.length }}个部门</span>
                            <span v-if="customRange.uid.length && customRange.dept.length">,</span>
                            <span v-if="customRange.uid.length">{{ customRange.uid.length }}个人员</span>
                        </el-button>
                        <el-button type="text" @click="openRange(0)" v-else>请选择</el-button>
                    </template>
                </div>
                <div class="control_limit" style="margin-top: 20px;">
                    <p>模板管理员</p>
                    <div @click="openRange(1)" class="saveBtn">请选择</div>
                    <div style="margin-top: 12px;">
                        <el-tag class="choose_item" v-for="(item, index) in conservatorList" :key="index"
                            style="margin-right: 4px;margin-bottom: 4px;">
                            {{ item.name }}
                        </el-tag>
                    </div>
                </div>
            </template>
            <template v-if="setupCode == 3">
                <div class="control_limit">
                    <p>摘要设置</p>
                    <div style="display: flex;margin-bottom: 12px;" @click="digestIf = 0">
                        <img src="@/assets/check4.png" alt="" v-if="digestIf == 0" />
                        <img src="@/assets/check3.png" alt="" v-else />
                        <span style="margin-left: 8px;">默认</span>
                        <span style="font-size: 12px;color: #848484;">（展示表单中前3个字段）</span>
                    </div>
                    <div style="display: flex;" @click="digestIf = 1">
                        <img src="@/assets/check4.png" alt="" v-if="digestIf == 1" />
                        <img src="@/assets/check3.png" alt="" v-else />
                        <span style="margin-left: 8px;">自定义</span>
                    </div>
                    <div v-if="digestIf == 1">
                        <div class="control_limit" style="">
                            <div @click="dialogCustomOpen" class="saveBtn" v-if="!dialogCustomdialog"
                                style="margin-top: 8px;">编辑</div>
                            <div v-if="!dialogCustomdialog" style="margin-top: 8px;">
                                <span v-for="item in fieCheckedObjList" :key="item.__vModel__"
                                    style="margin-right: 8px;">{{ item.label }},</span>
                            </div>
                            <div class="dialogMain" v-if="dialogCustomdialog">
                                <div style="display: flex;">
                                    <div class="leftBox">
                                        <div class="topBox">
                                            <span style="color: #fff;font-size: 14px;margin-right: 4px;">表单字段</span>
                                            <span style="color: #e4e5e7;font-size: 12px;">最多选{{ fieCheckedList.length
                                                }}/10个</span>
                                        </div>
                                        <div class="middleBox" style="border-right: 1px solid #f5f5f7;">
                                            <div class="Box" v-for="(item, index) in formEditorList" :key="index"
                                                style="display: flex;align-items: center;" @click="chooseField(item)">
                                                <img src="@/assets/check4.png" alt=""
                                                    v-if="fieCheckedList.includes(item.__vModel__)"
                                                    style="width: 16px;height: 16px;" />
                                                <img src="@/assets/check3.png" alt="" v-else
                                                    style="width: 16px;height: 16px;" />
                                                <span v-if="item.__vModel__">{{
                                                    item.__config__.label }}</span>
                                                <span style="color: #c1c1c1;" v-else>{{
                                                    item.__config__.form.标题 }}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="rightBox">
                                        <div class="topBox">
                                            <span style="color: #fff;font-size: 14px;">摘要显示字段</span>
                                        </div>
                                        <div class="middleBox" style="border-left: 1px solid #f5f5f7;">
                                            <draggable :list="fieCheckedObjList" :animation="340" item-key="__vModel__"
                                                handle=".Box" dragClass="dragClass">
                                                <div class="Box" v-for="item in fieCheckedObjList"
                                                    :key="item.__vModel__" style="display: flex;align-items: center;">
                                                    <img src="@/assets/Group_109.png" alt=""
                                                        style="width: 13px;height: 13px;" />
                                                    <span>{{ item.label }}</span>
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style="width: 100%;height:52px;background-color: #fff;display: flex;align-items: center;justify-content: end;border-top: 1px solid #f5f5f7;">
                                    <div @click="submitDegist"
                                        style="height: 36px;width: 84px;display: flex;align-items: center;justify-content: center;background-color: #5669ec;color: #fff;border-radius: 6px;margin-right: 8px;cursor: pointer;">
                                        确定
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </template>
        </div>
        <initiatingRange ref="initiatingRange" @confirm="getChooseList" :addType="addType" :selectedList="chooseList"
            :title="title" :isUser="isUser" />
    </div>
</template>

<script>
import initiatingRange from "../../initiatingRange.vue";
import draggable from "vuedraggable";
export default {
    components: {
        initiatingRange, draggable
    },
    data() {
        return {
            title: '',
            addType: '0',
            isUser: false,
            digestIf: 0,
            dialogCustomdialog: false,
            chooseList: [],
            fieldList: [],
            fieCheckedList: [],
            fieCheckedObjList: [],
            // 高级设置
            setupList: [
                { name: "流程设置", code: 1 },
                { name: "权限管理", code: 2 },
                { name: "摘要设置", code: 3 },
            ],
            deployList: [
                {
                    icon: require("@/assets/Group1.png"),
                    name: "自动去重",
                    value: 0,
                    label: "isDuplicate",
                    text: "审批流程中审批人重复出现时，只需审批一次其余自动通过",
                },
                {
                    icon: require("@/assets/Group2.png"),
                    name: "自动审批",
                    value: 1,
                    label: "isSponsorApproval",
                    text: "发起人节点自动通过",
                },
                {
                    icon: require("@/assets/Group3.png"),
                    name: "允许加签",
                    value: 1,
                    label: "isSign",
                    text: "开启后审批单可以新增临时审批人",
                },
                {
                    icon: require("@/assets/Group4.png"),
                    name: "撤销",
                    value: 1,
                    label: "isCancel",
                    text: "开启后发起人可以撤销审批单",
                },
                {
                    icon: require("@/assets/Group1000005489.png"),
                    name: "再次发起",
                    value: 1,
                    label: "isRepeat",
                    text: "开启后发起人可以重新发起",
                }
            ],
            controlLimit: [
                {
                    name: "全公司",
                    value: 1,
                },
                {
                    name: "自己负责的",
                    value: 0,
                },
                {
                    name: "同层级",
                    value: 2,
                },
                {
                    name: "本部门及部门以下",
                    value: 4,
                },
                {
                    name: "自定义",
                    value: 5,
                },
            ],
            setupCode: 1, //高级设置已选择
            authType: 0, //管理范围
            customRange: { dept: [], uid: [] },
            conservatorList: [],
            conservator: [],
            listType: 0,
            formEditorList: []
        };
    },
    methods: {
        chooseField(val) {
            if (val.__vModel__) {
                let index = this.fieCheckedList.indexOf(val.__vModel__)
                let index1 = this.fieCheckedObjList.findIndex(item => item.__vModel__ == val.__vModel__);
                if (index !== -1) {
                    this.fieCheckedList.splice(index, 1)
                    this.fieCheckedObjList.splice(index1, 1)
                } else {
                    if (this.fieCheckedList.length > 9) {
                        this.$message.warning('最多选择10个字段')
                        return
                    }
                    let obj = { __vModel__: val.__vModel__, label: val.__config__.label, value: val.__config__.defaultValue || '' }
                    this.fieCheckedObjList.push(obj)
                    this.fieCheckedList.push(val.__vModel__)
                }
            }
        },
        dialogCustomOpen() {
            this.fieCheckedObjList.forEach(itemA => {
                const itemB = this.formEditorList.find(itemB => itemB.__vModel__ === itemA.__vModel__);
                if (itemB) {
                    itemA.label = itemB.__config__.label;
                }
            });
            this.dialogCustomdialog = true
        },
        submitDegist() {
            this.fieCheckedObjList.forEach(itemA => {
                const itemB = this.formEditorList.find(itemB => itemB.__vModel__ === itemA.__vModel__);
                if (itemB) {
                    itemA.label = itemB.__config__.label;
                }
            });
            this.dialogCustomdialog = false
        },
        getChooseList(e) {
            if (this.listType == 0) {
                this.customRange.dept = [];
                this.customRange.uid = [];
                e.map((item) => {
                    this.customRange[item.type].push(item);
                });
            } else if (this.listType == 1) {
                this.conservator = e.map((item) => item.id)
                this.conservatorList = e
            }
        },
        openRange(type) {
            this.addType = "0";
            if (type == 0) {
                this.listType = 0
                this.initscope();
                this.isUser = false
                this.title = "选择管理范围";
            } else if (type == 1) {
                this.listType = 1
                this.title = "选择模板管理员";
                this.isUser = true
                console.log(`output->this.conservatorList`, this.conservatorList)
                if (this.conservatorList) {
                    this.chooseList = JSON.parse(
                        JSON.stringify(
                            this.conservatorList.map((e) => {
                                return {
                                    id: e.id,
                                    name: e.name,
                                    type: "uid",
                                };
                            })
                        )
                    );
                    console.log(`output->this.chooseList`, this.chooseList)
                }
            }
            this.$refs.initiatingRange.dialogVisible = true;
        },
        // 处理发起范围
        initscope() {
            let arr = [];
            for (const key in this.customRange) {
                if (this.customRange[key].length) {
                    this.customRange[key].map((e) => {
                        e.type = key;
                    });
                    arr.push(...this.customRange[key]);
                }
            }
            setTimeout(() => {
                this.chooseList = arr;
            }, 100);
        },
        chosseSetup(code) {
            this.setupCode = code;
        },
        chooseLimit(value) {
            this.authType = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.advanced_setup {
    height: 100%;
    display: flex;

    .left {
        padding: 20px 0;
        flex: 1;
        border-right: 2px solid #f5f5f7;
        position: relative;

        .setup_list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: calc(100% - 16px);

            .setup_item {
                border-radius: 4px;
                color: #848484;
                background: #f3f4f6;
                font-size: 13px;
                width: 100%;
                cursor: pointer;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .setup_active {
                background: #5669ec;
                color: #fff;

            }
        }
    }

    .right {
        flex: 4;
        padding: 16px 0 0 12px;

        >p {
            color: #1b1b1b;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 12px;
            margin-left: 4px;
        }

        .deploy_item {
            padding: 16px;
            border-radius: 12px;
            border: 1px solid #f5f5f7;
            height: 110px;
            background-color: #fff;
            margin: 0 4px 8px;

            .top {
                font-size: 13px;
                color: #1b1b1b;
                display: flex;
                justify-content: space-between;
                padding-bottom: 11px;
                align-items: center;
                height: 35px;
                border-bottom: 1px dashed #e4e5e7;
                margin-bottom: 11px;

                >div {
                    display: flex;
                    align-items: center;
                }

                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }

            .bottom {
                color: #848484;
                font-size: 11px;
            }
        }
    }

    .control_limit {
        margin-left: 4px;

        p {
            font-size: 13px;
            color: #1b1b1b;
            margin-bottom: 16px;
            font-weight: 400;
        }

        .list {
            display: flex;
            // justify-content: space-around;
            gap: 16px;

            .item {
                // flex: 1;
                // text-align: center;
                // background: #f5f5f7;
                // border-radius: 8px;
                // padding: 10px 0;
                // border: 1px solid #f5f5f7;
                cursor: pointer;
                color: #848484;
                font-size: 14px;
                display: flex;
                align-items: center;
                margin-right: 16px;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }

            // .item:hover {
            //     border: 1px solid #060000;
            // }

            // .limit_active {
            //     border: 1px solid #060000;
            //     color: #060000;
            // }
        }
    }

    .saveBtn {
        color: #fff;
        background-color: #5669ec;
        border-radius: 4px;
        width: 88px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        flex-shrink: 0;
    }

    .dialogMain {
        width: 575px;
        border-radius: 6px;
        overflow: hidden;
        margin-top: 8px;
        background-color: #fff;

        .leftBox {
            width: 288px;

            .Box {
                height: 26px;
                margin-left: 16px;

                img {
                    margin-right: 8px;
                }
            }

        }

        .rightBox {
            width: 50%;

            .Box {
                height: 36px;
                margin: 8px 16px;
                border: 1px solid #f3f4f6;
                border-radius: 4px;

                img {
                    margin-right: 8px;
                    margin-left: 8px;
                }
            }
        }

        .topBox {
            width: 100%;
            height: 39px;
            background-color: #5669ec;
            display: flex;
            align-items: center;
            padding: 12px;
        }

        .middleBox {
            max-height: calc(100vh - 400px);
            min-height: 240px;
            overflow-y: auto;
            background-color: #fff;
            font-size: 14px;

        }
    }

    .dragClass {
        background-color: #f3f4f6;
        height: 36px;
        margin: 8px 16px;
        border-radius: 4px;

        img {
            margin-right: 8px;
            margin-left: 8px;
        }
    }
}
</style>