var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advanced_setup" },
    [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          { staticClass: "setup_list" },
          _vm._l(_vm.setupList, function(item) {
            return _c(
              "div",
              {
                key: item.code,
                staticClass: "setup_item",
                class: _vm.setupCode == item.code ? "setup_active" : "",
                on: {
                  click: function($event) {
                    return _vm.chosseSetup(item.code)
                  }
                }
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.setupList.find(function(e) {
                  return e.code == _vm.setupCode
                }).name
              )
            )
          ]),
          _vm.setupCode == 1
            ? [
                _c(
                  "el-row",
                  _vm._l(_vm.deployList, function(item) {
                    return _c(
                      "el-col",
                      { key: item.name, attrs: { span: 8 } },
                      [
                        _c("div", { staticClass: "deploy_item" }, [
                          _c(
                            "div",
                            { staticClass: "top" },
                            [
                              _c("div", [
                                _c("img", {
                                  attrs: { src: item.icon, alt: "" }
                                }),
                                _c("span", [_vm._v(_vm._s(item.name))])
                              ]),
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#5669ec",
                                  "inactive-color": "#5669ec1a",
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "bottom" }, [
                            _vm._v(_vm._s(item.text))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              ]
            : _vm._e(),
          _vm.setupCode == 2
            ? [
                _c(
                  "div",
                  { staticClass: "control_limit" },
                  [
                    _c("p", [_vm._v("管理范围")]),
                    _c(
                      "div",
                      { staticClass: "list" },
                      _vm._l(_vm.controlLimit, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.value,
                            staticClass: "item",
                            on: {
                              click: function($event) {
                                return _vm.chooseLimit(item.value)
                              }
                            }
                          },
                          [
                            _vm.authType == item.value
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/check4.png"),
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("@/assets/check3.png"),
                                    alt: ""
                                  }
                                }),
                            _c("div", [_vm._v(_vm._s(item.name))])
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.authType == 5
                      ? [
                          _vm.customRange.uid.length ||
                          _vm.customRange.dept.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openRange(0)
                                    }
                                  }
                                },
                                [
                                  _vm.customRange.dept.length
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.customRange.dept.length) +
                                            "个部门"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.customRange.uid.length &&
                                  _vm.customRange.dept.length
                                    ? _c("span", [_vm._v(",")])
                                    : _vm._e(),
                                  _vm.customRange.uid.length
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.customRange.uid.length) +
                                            "个人员"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openRange(0)
                                    }
                                  }
                                },
                                [_vm._v("请选择")]
                              )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "control_limit",
                    staticStyle: { "margin-top": "20px" }
                  },
                  [
                    _c("p", [_vm._v("模板管理员")]),
                    _c(
                      "div",
                      {
                        staticClass: "saveBtn",
                        on: {
                          click: function($event) {
                            return _vm.openRange(1)
                          }
                        }
                      },
                      [_vm._v("请选择")]
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "12px" } },
                      _vm._l(_vm.conservatorList, function(item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticClass: "choose_item",
                            staticStyle: {
                              "margin-right": "4px",
                              "margin-bottom": "4px"
                            }
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      1
                    )
                  ]
                )
              ]
            : _vm._e(),
          _vm.setupCode == 3
            ? [
                _c("div", { staticClass: "control_limit" }, [
                  _c("p", [_vm._v("摘要设置")]),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "12px" },
                      on: {
                        click: function($event) {
                          _vm.digestIf = 0
                        }
                      }
                    },
                    [
                      _vm.digestIf == 0
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/check4.png"),
                              alt: ""
                            }
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/check3.png"),
                              alt: ""
                            }
                          }),
                      _c("span", { staticStyle: { "margin-left": "8px" } }, [
                        _vm._v("默认")
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: { "font-size": "12px", color: "#848484" }
                        },
                        [_vm._v("（展示表单中前3个字段）")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex" },
                      on: {
                        click: function($event) {
                          _vm.digestIf = 1
                        }
                      }
                    },
                    [
                      _vm.digestIf == 1
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/check4.png"),
                              alt: ""
                            }
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/check3.png"),
                              alt: ""
                            }
                          }),
                      _c("span", { staticStyle: { "margin-left": "8px" } }, [
                        _vm._v("自定义")
                      ])
                    ]
                  ),
                  _vm.digestIf == 1
                    ? _c("div", [
                        _c("div", { staticClass: "control_limit" }, [
                          !_vm.dialogCustomdialog
                            ? _c(
                                "div",
                                {
                                  staticClass: "saveBtn",
                                  staticStyle: { "margin-top": "8px" },
                                  on: { click: _vm.dialogCustomOpen }
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                          !_vm.dialogCustomdialog
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "8px" } },
                                _vm._l(_vm.fieCheckedObjList, function(item) {
                                  return _c(
                                    "span",
                                    {
                                      key: item.__vModel__,
                                      staticStyle: { "margin-right": "8px" }
                                    },
                                    [_vm._v(_vm._s(item.label) + ",")]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm.dialogCustomdialog
                            ? _c("div", { staticClass: "dialogMain" }, [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("div", { staticClass: "leftBox" }, [
                                      _c("div", { staticClass: "topBox" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#fff",
                                              "font-size": "14px",
                                              "margin-right": "4px"
                                            }
                                          },
                                          [_vm._v("表单字段")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#e4e5e7",
                                              "font-size": "12px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "最多选" +
                                                _vm._s(
                                                  _vm.fieCheckedList.length
                                                ) +
                                                "/10个"
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "middleBox",
                                          staticStyle: {
                                            "border-right": "1px solid #f5f5f7"
                                          }
                                        },
                                        _vm._l(_vm.formEditorList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "Box",
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.chooseField(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm.fieCheckedList.includes(
                                                item.__vModel__
                                              )
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "16px",
                                                      height: "16px"
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/check4.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "16px",
                                                      height: "16px"
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/check3.png"),
                                                      alt: ""
                                                    }
                                                  }),
                                              item.__vModel__
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.__config__.label
                                                      )
                                                    )
                                                  ])
                                                : _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#c1c1c1"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.__config__.form
                                                            .标题
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]),
                                    _c("div", { staticClass: "rightBox" }, [
                                      _vm._m(0),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "middleBox",
                                          staticStyle: {
                                            "border-left": "1px solid #f5f5f7"
                                          }
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              attrs: {
                                                list: _vm.fieCheckedObjList,
                                                animation: 340,
                                                "item-key": "__vModel__",
                                                handle: ".Box",
                                                dragClass: "dragClass"
                                              }
                                            },
                                            _vm._l(
                                              _vm.fieCheckedObjList,
                                              function(item) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.__vModel__,
                                                    staticClass: "Box",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "13px",
                                                        height: "13px"
                                                      },
                                                      attrs: {
                                                        src: require("@/assets/Group_109.png"),
                                                        alt: ""
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.label))
                                                    ])
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "52px",
                                      "background-color": "#fff",
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "end",
                                      "border-top": "1px solid #f5f5f7"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "36px",
                                          width: "84px",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                          "background-color": "#5669ec",
                                          color: "#fff",
                                          "border-radius": "6px",
                                          "margin-right": "8px",
                                          cursor: "pointer"
                                        },
                                        on: { click: _vm.submitDegist }
                                      },
                                      [_vm._v(" 确定 ")]
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _c("initiatingRange", {
        ref: "initiatingRange",
        attrs: {
          addType: _vm.addType,
          selectedList: _vm.chooseList,
          title: _vm.title,
          isUser: _vm.isUser
        },
        on: { confirm: _vm.getChooseList }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "topBox" }, [
      _c("span", { staticStyle: { color: "#fff", "font-size": "14px" } }, [
        _vm._v("摘要显示字段")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }