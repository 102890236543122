<!--  -->
<template>
    <div class="bpm-container">
        <header>
            <div class="step">
                <div class="step_item" :class="stepActive == item.code ? 'active' : ''" @click="chooseStep(item.code)"
                    v-for="item in stepList" :key="item.code">
                    {{ item.name }}
                </div>
            </div>
            <div class="btns">
                <div @click="goBack">取消</div>
                <!-- <div @click="handleDetail">预览</div> -->
                <div @click="publish('ruleForm', 1)" v-if="saveBtn" style="color: #fff;background-color: #5669ec;">保存
                </div>
                <div @click="publish('ruleForm', 0)" style="margin-right: 0;color: #fff;background-color: #5669ec;">发布
                </div>
            </div>
        </header>
        <main>
            <!-- 基础信息1-->
            <div v-show="stepActive == 1" class="info_form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                    style="width: 492px" :label-position="'top'">
                    <el-form-item label="流程名称" prop="name">
                        <el-input v-model="ruleForm.name" @input="inputName"></el-input>
                    </el-form-item>
                    <el-form-item label="流程标识" prop="tag" v-show="false">
                        <el-input v-model="ruleForm.tag" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="图标" style="margin-bottom: 0!important    ;">
                        <upload :icon="ruleForm.icon" @geturl="geturl" />
                    </el-form-item>
                    <el-form-item label="发起范围" prop="scope" style="display: block;">
                        <div @click="openRange" class="saveBtn">请选择</div>
                    </el-form-item>
                    <div v-if="chooseList.length" class="choose_list">
                        <el-tag class="choose_item" v-for="(item, index) in chooseList" :key="index"
                            style="margin-right: 4px;margin-bottom: 4px;">
                            {{ item.name }}
                        </el-tag>
                    </div>
                    <el-form-item label="流程描述">
                        <el-input type="textarea" v-model="ruleForm.description"></el-input>
                    </el-form-item>
                    <el-form-item label="表单说明">
                        <el-input type="textarea" v-model="remark" :rows="4"></el-input>
                        <span style="color:rgb(96, 98, 102);font-size: 12px;">编辑内容后，表单说明会展示于发起表单顶部</span>
                    </el-form-item>

                </el-form>
                <initiatingRange ref="initiatingRange" @confirm="getChooseList" :selectedList="chooseList" />
                <!--表单配置详情-->
                <!-- <el-dialog ref="parser" title="表单预览" :visible.sync="detailOpen" width="50%" append-to-body>
                    <div class="test-form">
                        <parser :key="new Date().getTime()" :form-conf="detailForm" :disabled="false" />
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="detailOpen = false">关 闭</el-button>
                    </span>
                </el-dialog> -->
            </div>
            <!-- 表单设计2 -->
            <div v-show="stepActive == 2" class="bpm">
                <div class="form_box">
                    <formEditor ref="formEditor" :checkList="checkList" :formInfo="this.ruleForm.form"
                        @changeForm="changeForm" :bpmn-modeler="modeler" @changeXml="changeXml" />
                </div>
            </div>
            <!-- 流程设计3 -->
            <div v-show="stepActive == 3" class="bpm">
                <!-- 开发版 -->
                <div class="bpm_box">
                    <my-process-designer v-if="xmlString !== undefined && model.key" :key="`designer-${reloadIndex}`"
                        :formId="11" v-model="xmlString" v-bind="controlForm" :processId="model.key"
                        :processName="model.name" keyboard ref="processDesigner" @init-finished="initModeler"
                        @change="changeBpm" @save="getbpm" />
                    <!-- 流程属性器，负责编辑每个流程节点的属性 -->
                    <my-properties-panel v-if="panelFlag" :key="`penal-${reloadIndex}`" :bpmn-modeler="modeler"
                        :prefix="controlForm.prefix" class="process-panel" :model="model" />
                </div>
            </div>
            <!-- 高级设置4 -->
            <div v-show="stepActive == 4">
                <advancedSetup ref="advancedSetup" />
            </div>
        </main>

        <Customdialog v-if='PublishDialog' :dialogWidth="'300px'" :title="'提示'" :dialogHeight="'140px'" :isFooter="true"
            @closeDialog="clickPublish" @submitDialog="submitPublish" v-loading="loading"
            element-loading-spinner="el-icon-loading" element-loading-background="#ffffffcf"
            :element-loading-text="tetype == 0 ? '正在发布' : '正在保存'">
            <template slot="dialogMain">
                <div v-if="!loading">
                    {{ tetype == 0 ? '确认发布吗？' : '确认保存吗？' }}
                </div>
            </template>
        </Customdialog>
        <Customdialog v-if='cancelDialog' :dialogWidth="'300px'" :title="'提示'" :dialogHeight="'140px'" :isFooter="true"
            @closeDialog="cancelDialog = false" @submitDialog="goBackTo" element-loading-background="#ffffffcf">
            <template slot="dialogMain">
                <div>
                    确认取消吗？
                </div>
            </template>
        </Customdialog>
    </div>
</template>


<script>
import { uniq } from "lodash-es";
import initiatingRange from "../initiatingRange.vue";
import advancedSetup from "./components/advancedSetup.vue";
import upload from "@/components/upload.vue";
import formEditor from "./form/formEditor.vue";
import ProcessDesign from "@/components/process/ProcessDesign";
import MyProcessPalette from "@/components/bpmnProcessDesigner/package/palette/ProcessPalette";
// 自定义元素选中时的弹出菜单（修改 默认任务 为 用户任务）  
import CustomContentPadProvider from "@/components/bpmnProcessDesigner/package/designer/plugins/content-pad";
// 自定义左侧菜单（修改 默认任务 为 用户任务）
import CustomPaletteProvider from "@/components/bpmnProcessDesigner/package/designer/plugins/palette";
import { cloneDeep } from "lodash-es";
import Customdialog from '@/components/customdialog'

import {

    getFlowKey,
    addFlowManagement,
    flowManagement,
    editFlowManagement,
    simpleSave,
    simpleEdit,
} from "@/api/flow";
export default {
    name: 'CreateFlow',
    components: { initiatingRange, upload, advancedSetup, formEditor, MyProcessPalette, ProcessDesign, Customdialog },
    data() {
        return {
            tetype: 0,
            PublishDialog: false,
            panelFlag: true,
            loading: false,
            detailOpen: false, //预览
            detailForm: {
                fields: [],
            },
            checkList: [], //bpm已经选择条件的组件
            rules: {
                name: [{ required: true, message: "请输入流程名称", trigger: "blur" }],
                tag: [{ required: true, message: "请输入流程标识", trigger: "blur" }],
                formId: [
                    { required: true, message: "请选择流程表单", trigger: "change" },
                ],
                scope: [
                    { required: true, message: "请选择发起范围", trigger: "change" },
                ],
            },

            stepActive: 1, //步骤
            chooseList: [], //已选择发起范围
            flowId: "", //编辑id
            ruleForm: {
                groupId: "",
                name: "", //流程标识
                tag: "", //流程标识
                formId: "", //表单ID
                description: "", //流程描述
                icon: "",
                scope: "",
                status: 0,
                isSimple: 999,
                form: {},
                conservator: []
            },
            roleOptions: [],
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            stepList: [
                { name: "01 基础信息", code: 1 },
                { name: "02 表单设计", code: 2 },
                { name: "03 流程设计", code: 3 },
                { name: "04 高级设置", code: 4 },
            ],
            formsOption: [], //流程表单下啦
            xmlString: undefined, // BPMN XML
            modeler: null,
            reloadIndex: 0,
            controlForm: {
                simulation: true,
                labelEditing: false,
                labelVisible: false,
                prefix: "flowable",
                headerButtonSize: "mini",
                additionalModel: [CustomContentPadProvider, CustomPaletteProvider],
            },
            addis: {
                CustomContentPadProvider,
                CustomPaletteProvider,
            },
            // 流程模型的信息
            model: {
                key: "",
                name: "",
            },
            saveBtn: true,
            remark: '',
            isNav: true,
            cancelDialog: false
        };
    },
    //监听属性 类似于data概念
    computed: {
        refreshKey() {
            return this.$store.state.process.refreshKey;
        },

    },
    //监控data中的数据变化
    watch: {
        $route(to, from) {
            console.log(`output->1234555`, 1234)
        },
    },
    filters: {},

    methods: {
        // 选择步骤
        chooseStep(code) {
            if (code == 4) {
                let form = this.$refs.formEditor.encodeFields()
                form.forEach((e, i, s) => {
                    return s[i] = JSON.parse(e)
                })
                this.$refs.advancedSetup.formEditorList = form
            }
            this.stepActive = code;
        },
        goBack() {
            this.cancelDialog = true
            // this.$store.commit("CHANGE_refreshKey", 1);
            // this.$router.push('/main/workbench/ProcessSetting/backstage');
            // this.$destroy();
        },
        goBackTo() {
            this.$store.commit("CHANGE_refreshKey", 1);
            this.$router.push('/main/workbench/ProcessSetting/backstage');
        },
        handleDetail() {

        },
        // 点击发布按钮
        publish(formName, type) {
            this.tetype = type
            this.$refs[formName].validate(async (valid) => {
                // console.log(`output->valid`, valid)
                if (valid) {

                    // 验证表单设计
                    let formEditor = this.$refs.formEditor;
                    if (!formEditor.encodeFields().length) {
                        this.stepActive = 2;
                        this.$message.error("请设计表单");
                        return false;
                    }
                    let formvalidate = null;
                    this.$refs.formEditor.$refs.RightPanel.$refs[
                        "dynamicValidateForm"
                    ].validate((valid) => {
                        // console.log(`output->valid1`, this.$refs.formEditor.$refs.RightPanel.$refs[
                        //   "dynamicValidateForm"
                        // ])
                        if (!valid) {
                            formvalidate = false;
                            this.stepActive = 2;
                            return formvalidate;
                        } else {
                            formvalidate = true;
                            return formvalidate;
                        }
                    });
                    if (!formvalidate) return;
                    this.ruleForm.form = {
                        conf: JSON.stringify(formEditor.formConf), // 表单配置
                        fields: formEditor.encodeFields(), // 表单项的数组
                        remark: this.remark,
                        ...formEditor.form,
                    };
                    let va = true
                    this.ruleForm.form.fields.forEach((e) => {
                        let json = JSON.parse(e);
                        if (json.__vModel__) {
                            if (json.__config__.type == "approval" && (json.__config__.approval == '' || json.__config__.approval.length == 0 || json.__config__.approval == undefined)) {
                                this.$message.error(`【${json.__config__.label}】中指定关联审批不能为空`);
                                va = false
                            }
                        } else {
                            json.__config__.children[0].forEach((i) => {
                                if (i.__config__.type == "approval" && (i.__config__.approval == '' || i.__config__.approval.length == 0 || i.__config__.approval == undefined)) {
                                    this.$message.error(`【${i.__config__.label}】中指定关联审批不能为空`);
                                    va = false
                                }
                            })
                        }
                    });
                    if (!va) return
                    //验证流程设计
                    let a = await this.$refs.processDesigner.processSave();
                    if (!a) return;
                    if (!this.ruleForm.bpmnXml) {
                        this.stepActive = 3;
                        return;
                    }
                    // 高级设置
                    this.$refs.advancedSetup.deployList.map((e) => {
                        this.ruleForm[e.label] = e.value;
                    });
                    this.ruleForm.authType = this.$refs.advancedSetup.authType;
                    if (this.ruleForm.authType == 5) {
                        let { dept, uid } = this.$refs.advancedSetup.customRange;
                        if (!dept.length && !uid.length) {
                            this.$message.error("请选择自定义管理范围");
                            this.stepActive = 4;
                            return;
                        } else {
                            this.ruleForm.customRange = this.$refs.advancedSetup.customRange;
                        }
                    }
                    this.ruleForm.conservator = this.$refs.advancedSetup.conservator
                    if (this.ruleForm.conservator && this.ruleForm.conservator.length == 0) {
                        this.$message.error("请选择流程模板管理员");
                        this.stepActive = 4;
                        return;
                    }

                    this.ruleForm.form.fields.forEach((e, ii, s) => {
                        let json = JSON.parse(e);
                        if (!json.__vModel__) {
                            json.__config__.children[0].forEach((i, index, self) => {
                                if (json.__config__.form.填写方式 === '表格') {
                                    self[index].__config__.showLabel = false
                                } else {
                                    self[index].__config__.showLabel = true
                                }
                            })
                            s[ii] = JSON.stringify(json)
                        }
                    });
                    let b = this.ruleForm.form.fields.map(i => JSON.parse(i))
                    let digestInfo = this.$refs.advancedSetup.fieCheckedObjList
                    if (digestInfo.length && digestInfo.length > 0) {
                        const bIds = new Set(b.map(item => item.__vModel__));
                        const arr = digestInfo.filter(itemA => bIds.has(itemA.__vModel__));
                        arr.forEach(a => {
                            const itemB = b.find(itemB => itemB.__vModel__ === a.__vModel__);
                            if (itemB) {
                                a.label = itemB.__config__.label;
                            }
                        });
                        this.ruleForm.digestInfo = JSON.stringify(arr)
                    }
                    this.ruleForm.digestIf = this.$refs.advancedSetup.digestIf
                    this.PublishDialog = true

                } else {
                    this.stepActive = 1;
                    return false;
                }
            });
        },
        submitPublish() {
            this.addFlag ? this.addFlowManagement(this.tetype) : this.editFlowManagement(this.tetype);
        },
        clickPublish() {
            this.PublishDialog = false
        },
        // 新增发开流程
        async addFlowManagement(type) {
            // console.log(this.ruleForm);

            this.loading = true;
            this.ruleForm.status = type;
            this.ruleForm.icon = this.ruleForm.icon || this.$store.state.bpm.icon;
            this.ruleForm.oldId = this.flowId;
            addFlowManagement(this.ruleForm)
                .then((res) => {
                    if (res.code == 200) {
                        this.loading = false;
                        this.PublishDialog = false
                        this.$message.success(`${type == 0 ? "发布" : "保存"}成功`);
                        this.$destroy();
                        this.$router.push('/main/workbench/ProcessSetting/backstage')
                    } else {
                        this.$message.error(res.msg)
                        this.loading = false;
                    }
                })
                .catch((res) => (this.loading = false));
        },
        // 修改发开流程
        async editFlowManagement(type) {

            this.loading = true;
            this.ruleForm.status = type;
            editFlowManagement(this.ruleForm)
                .then((res) => {
                    if (res.code == 200) {
                        this.loading = false;
                        this.PublishDialog = false
                        this.ruleForm.bpmnXml = null;
                        this.$message.success(`${type == 0 ? "发布" : "保存"}成功`);
                        this.$destroy();
                        this.$router.push('/main/workbench/ProcessSetting/backstage')
                    } else {
                        this.$message.error(res.msg)
                        this.loading = false;
                    }

                })
                .catch((res) => (this.loading = false));
        },
        openRange() {
            this.initscope();
            this.$refs.initiatingRange.dialogVisible = true;

        },
        initscope() {
            let arr = [];
            for (const key in this.ruleForm.scope) {
                if (this.ruleForm.scope[key].length) {
                    this.ruleForm.scope[key].map((e) => {
                        e.type = key;
                    });
                    arr.push(...this.ruleForm.scope[key]);
                }
            }
            setTimeout(() => {
                this.chooseList = arr;
            }, 100);
        },
        getChooseList(e) {
            this.chooseList = e;
            let arr = ["dept", "role", "uid", "group"];
            let obj = {};
            e.map((e) => {
                arr.map((i) => {
                    if (e.type == i) {
                        if (!obj[i]) {
                            obj[i] = [];
                        }
                        obj[i].push(e);
                    }
                });
            });
            this.ruleForm.scope = obj;
        },
        inputName(e) {
            this.model.name = e;
        },
        geturl(e) {
            this.ruleForm.icon = e.url;
        },
        changeXml(xml) {
            this.xmlString = xml;
            this.$refs.processDesigner.createNewDiagram(xml);
        },
        changeForm(e) {
            let formArr = e.map((item) => {
                return {
                    label: item.fieldVal + "_" + item.fieldName,
                    value: 0,
                };
            });

            this.xmlString = this.changedXml(this.xmlString, formArr);
            console.log(`output->112`, this.$refs.processDesigner)
            console.log(`output->xmlString !== undefined && model.key`, this.xmlString !== undefined, this.model.key)
            this.$refs.processDesigner.createNewDiagram(this.xmlString, 5);
        },
        changedXml(xmlString, formList) {
            // 假设有如下XML字符串
            // 解析XML字符串
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(xmlString, "text/xml");
            // 编辑XML
            // 例如，修改element元素的text内容
            let userTask = xmlDoc.getElementsByTagName("bpmn2:userTask");
            let serviceTask = xmlDoc.getElementsByTagName("bpmn2:serviceTask");
            let startEvent = xmlDoc.getElementsByTagName("bpmn2:startEvent");
            // console.log(`output->startEvent`, startEvent)
            // console.log(`output->userTask`, userTask)
            let line = startEvent[0]?.childNodes[1]?.innerHTML;
            // console.log(`output->line`, line)

            for (let index = 0; index < userTask.length; index++) {
                let regulator = [];
                let e = userTask[index];
                let list = JSON.parse(e.getAttribute("regulator"));
                // if (e.getAttribute("id") == 'Activity_1718jvk') {
                //   console.log(`output->formList`, formList)
                //   formList.forEach((item) => item.value = 1);
                //   console.log(`output->formList1`, formList)
                // }
                regulator = cloneDeep(formList);
                if (list) {
                    regulator.map((i) => {
                        list.map((o) => {
                            if (i.label == o.label) {
                                i.value = o.value;
                            }
                        });
                    });
                }
                if (e.childNodes[1].innerHTML == line) {
                    if (!list) {
                        list = []
                    }
                    const diff = regulator.filter(bItem => !list.some(aItem => aItem.label == bItem.label));
                    diff.forEach(item => item.value = 1);
                }
                e.setAttribute("regulator", JSON.stringify(regulator));
            }
            for (let index = 0; index < serviceTask.length; index++) {
                let regulator = [];
                let e = serviceTask[index];
                let list = JSON.parse(e.getAttribute("regulator"));
                regulator = cloneDeep(formList);
                if (list) {
                    regulator.map((i) => {
                        list.map((o) => {
                            if (i.label == o.label) {
                                i.value = o.value;
                            }
                        });
                    });
                }
                e.setAttribute("regulator", JSON.stringify(regulator));
            }
            // 将修改后的DOM对象转换回XML字符串
            let serializer = new XMLSerializer();
            let newXmlString = serializer.serializeToString(xmlDoc);
            return newXmlString;
        },
        changeBpm(e, i) {
            let flagUserTask = i.rootElements[0].flowElements.filter(
                (e) => e.$type == "bpmn:SequenceFlow"
            );
            if (flagUserTask.length) {
                let checkList = [];
                flagUserTask.map((e) => {
                    if (e.$attrs.conditionList) {
                        let conditionList = JSON.parse(e.$attrs.conditionList);
                        conditionList.map((i) => {
                            i.checkList.map((p) => {
                                checkList.push(p);
                            });
                        });
                    }
                });
                checkList = uniq(checkList);
                this.checkList = checkList;
                this.$store.commit("bpm/CHANGE_CHECKLIST", checkList);
            }
        },
        init() {
            if (!this.flowId) {
                this.xmlString = null;
            }
            this.$store.dispatch("bpm/listDeptUser");
            this.$store.dispatch("bpm/listSimpleRoles", "");
            this.$store.dispatch("bpm/listSimpleUsers");
            this.$store.dispatch("bpm/listSimpleDepts");
            this.$store.dispatch("bpm/findGroupsList");
            // this.$store.dispatch("bpm/listSimpleUserGroups");
        },
        // 自动生产流程标识key
        async getFlowKey() {
            if (!this.addFlag) return;
            const { data } = await getFlowKey();
            this.ruleForm.tag = data;
            this.model.key = data;
        },
        // 获取详情
        async flowManagement() {
            if (!this.flowId) return;
            const { data } = await flowManagement(this.flowId);
            console.log(`output->data`, data)
            if (this.addFlag) {
                delete data.formId;
                delete data.form.id;
                delete data.tag;
                delete data.id;
                delete data.modelId;
                delete data.groupId;
                this.saveBtn = true
            } else {
                this.model = {
                    key: data.tag,
                    name: "",
                    bpmnXml: undefined,
                };
            }
            // console.log(data.form, "data.form");
            if (data.status == 1) {
                this.saveBtn = true
            }

            // this.$refs.formEditor.form.remark = data.form.remark;
            this.remark = data.form.remark
            this.$refs.formEditor.form.name = data.form.name;
            this.$refs.formEditor.form.status = data.form.status;

            this.model.name = data.name;
            this.ruleForm = Object.assign(this.ruleForm, data);
            this.$refs.advancedSetup.deployList.map((e) => {
                e.value = this.ruleForm[e.label];
            });
            this.$refs.advancedSetup.authType = this.ruleForm.authType;
            this.$refs.advancedSetup.customRange = this.ruleForm.customRange || {
                dept: [],
                uid: [],
            };
            this.ruleForm.conservator = data.conservatorList.map(e => e.id);
            this.$refs.advancedSetup.conservator = this.ruleForm.conservator
            this.$refs.advancedSetup.conservatorList = data.conservatorList;
            this.$refs.advancedSetup.digestIf = data.digestIf || 0
            this.$refs.advancedSetup.fieCheckedObjList = JSON.parse(data.digestInfo) || []
            this.$refs.advancedSetup.fieCheckedList = JSON.parse(data.digestInfo)?.map(item => item.__vModel__) || []


            this.xmlString = data.bpmnXml;
            // 处理发起范围
            this.initscope();
        },
        initModeler(modeler) {
            this.modeler = null;
            setTimeout(() => {
                this.modeler = modeler;
                //console.log(`output->2modeler`, modeler)
            }, 100);
        },
        getbpm(e) {
            if (!e) return;
            this.ruleForm.bpmnXml = e;
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.isNav = true
        this.$store.dispatch("dict/loadDictDatas");
        this.addFlag = this.$route.query.id ? true : false;
        this.ruleForm.groupId = this.$route.query.id;
        console.log(`output->this.$route.query`, this.$route.query)
        this.flowId = this.$route.query.flowId;
        console.log(`output->this.flowId`, this.flowId)
        if (this.flowId) {
            this.saveBtn = false
        }
        // // 获取表单下啦
        this.init();
        // // 获取流程标识
        this.getFlowKey();
        // // 获取编辑详情
        this.flowManagement();

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    //如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>
<style lang='scss' scoped>
::v-deep .el-form-item {
    display: grid;
    margin-bottom: 18px !important;
}


::v-deep .el-form-item__label {
    line-height: 36px;
    color: #000000;
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
}

.bpm-container {
    padding: 16px;
    height: calc(100% - 32px);

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .step {
            display: flex;

            .step_item {
                color: #848484;
                font-size: 12px;
                padding: 8px 0;
                cursor: pointer;
                width: 98px;
                border-bottom: 2px solid #f3f4f6;
            }

            .active {
                color: #5669ec;
                border-bottom: 2px solid #5669ec;
            }
        }

        .btns {
            display: flex;

            div {
                width: 72px;
                height: 34px;
                border-radius: 6px;
                background: #f3f4f6;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #848484;
                margin-right: 8px;
                cursor: pointer;
            }
        }
    }

    main {
        text-align: left;
        /* flex: 1; */
        overflow: hidden;
        height: calc(100% - 44px);

        .info_form {
            padding: 16px;
            height: 100%;
            background: #fff;
            border-radius: 6px;
            overflow: auto;
            height: 100%;

            .saveBtn {
                color: #fff;
                background-color: #5669ec;
                border-radius: 4px;
                width: 112px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                flex-shrink: 0;
            }
        }

        .bpm {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        .bpm_tabs {
            flex: 0 0 24px;
            display: flex;
            gap: 12px;

            div {
                padding: 4px 18px;
                font-size: 14px;
                color: #727272;
                border: 1px solid #e1e1e1;
                border-radius: 2px 20px 20px 12px;
                cursor: pointer;
            }

            .active {
                border: 1px solid #060000;
                background: #060000;
                color: #fff;
            }
        }

        .bpm_box {
            flex: 1;
            background: #fff;
            border-radius: 16px;
            position: relative;
        }

        .form_box {
            background: #fff;
            border-radius: 16px;
            overflow: hidden;
            height: 100%;
        }

        .choose_list {

            /* margin-top: 10px;
            max-height: 300px;
            border: 1px solid #dddfe5;
            padding: 10px;
            overflow: auto;
            border-radius: 4px; */
        }

        >div {
            height: 100%;
            position: relative;
        }
    }

    ::v-deep .el-button--primary {
        text-align: left;
        border-radius: 30px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background: #050000;
        border: 1px solid #050000; */
    }

    .process-panel {

        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    :deep(.test-form) {
        text-align: left;

        .el-form {
            display: flex;
            flex-direction: column;
        }

        .table-box {
            // max-width: 600px;
            overflow-y: auto;
            margin-bottom: 40px;
            max-height: 400px;

            .add-btn {
                margin: 20px 0 20px 20px;
            }
        }

        .header-row {
            display: flex;
        }

        .table-row {
            // margin: 0 20px;
            padding: 0 20px;

            .itemContent {
                margin-bottom: 10px;
                display: flex;

                .el-col {
                    display: flex;
                    border-bottom: 1px solid #dfe6ec;
                    justify-content: center;
                    align-items: center;
                    padding: 40px 20px;
                    box-sizing: border-box;
                    min-width: 300px;
                }

                .el-form-item {
                    margin: 0;
                    width: 100%;
                }
            }

            .itemTitle {
                display: flex;
                align-items: center;
                padding: 0 20px;

                .text {
                    margin-right: 20px;
                }
            }
        }

        .table-header {
            text-align: center;
            font-weight: bold;
            background-color: #f8f8f9;
            padding: 10px;
            border-bottom: 1px solid #dfe6ec;
            display: flex;
            min-width: 300px;
            justify-content: center;
        }

        .ul-row {
            border-radius: 10px;
            background-color: #f8f8f9;
            box-sizing: border-box;
            margin: 20px;
            padding: 20px;

            .itemTitle {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

}
</style>